export default [
    // {
    //     label: '',
    //     value: '',
    //     displayOrder: -1,
    //     doubleData: 0,
    //     hidden: false,
    //     description: '',
    //     readOnly: false,
    // },
    {
        label: 'Afghanistan',
        value: 'Afghanistan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Albania',
        value: 'Albania',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Algeria',
        value: 'Algeria',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'American Samoa',
        value: 'American Samoa',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Andorra',
        value: 'Andorra',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Angola',
        value: 'Angola',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Anguilla',
        value: 'Anguilla',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Antarctica',
        value: 'Antarctica',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Antigua and Barbuda',
        value: 'Antigua and Barbuda',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Argentina',
        value: 'Argentina',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Armenia',
        value: 'Armenia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Aruba',
        value: 'Aruba',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Asia/Pacific Region',
        value: 'Asia/Pacific Region',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Australia',
        value: 'Australia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Austria',
        value: 'Austria',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Azerbaijan',
        value: 'Azerbaijan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bahamas',
        value: 'Bahamas',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bahrain',
        value: 'Bahrain',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bangladesh',
        value: 'Bangladesh',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Barbados',
        value: 'Barbados',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Belarus',
        value: 'Belarus',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Belgium',
        value: 'Belgium',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Belize',
        value: 'Belize',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Benin',
        value: 'Benin',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bermuda',
        value: 'Bermuda',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bhutan',
        value: 'Bhutan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bolivia',
        value: 'Bolivia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bosnia and Herzegovina',
        value: 'Bosnia and Herzegovina',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Botswana',
        value: 'Botswana',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bouvet Island',
        value: 'Bouvet Island',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Brazil',
        value: 'Brazil',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'British Indian Ocean Territory',
        value: 'British Indian Ocean Territory',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'British Virgin Islands',
        value: 'British Virgin Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Brunei',
        value: 'Brunei',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Bulgaria',
        value: 'Bulgaria',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Burkina Faso',
        value: 'Burkina Faso',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Burundi',
        value: 'Burundi',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cambodia',
        value: 'Cambodia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cameroon',
        value: 'Cameroon',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Canada',
        value: 'Canada',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Canary Islands',
        value: 'Canary Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cape Verde',
        value: 'Cape Verde',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Caribbean Netherlands',
        value: 'Caribbean Netherlands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cayman Islands',
        value: 'Cayman Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Central African Republic',
        value: 'Central African Republic',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Chad',
        value: 'Chad',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Chile',
        value: 'Chile',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'China',
        value: 'China',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Christmas Island',
        value: 'Christmas Island',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cocos (Keeling) Islands',
        value: 'Cocos (Keeling) Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Colombia',
        value: 'Colombia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Comoros',
        value: 'Comoros',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Congo',
        value: 'Congo',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cook Islands',
        value: 'Cook Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Costa Rica',
        value: 'Costa Rica',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: "Cote d'Ivoire",
        value: "Cote d'Ivoire",
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Croatia',
        value: 'Croatia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cuba',
        value: 'Cuba',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Curaçao',
        value: 'Curaçao',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Cyprus',
        value: 'Cyprus',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Czech Republic',
        value: 'Czech Republic',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Democratic Republic of the Congo',
        value: 'Democratic Republic of the Congo',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Denmark',
        value: 'Denmark',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Djibouti',
        value: 'Djibouti',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Dominica',
        value: 'Dominica',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Dominican Republic',
        value: 'Dominican Republic',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'East Timor',
        value: 'East Timor',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Ecuador',
        value: 'Ecuador',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Egypt',
        value: 'Egypt',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'El Salvador',
        value: 'El Salvador',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Equatorial Guinea',
        value: 'Equatorial Guinea',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Eritrea',
        value: 'Eritrea',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Estonia',
        value: 'Estonia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Ethiopia',
        value: 'Ethiopia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Europe',
        value: 'Europe',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Falkland Islands',
        value: 'Falkland Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Faroe Islands',
        value: 'Faroe Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Fiji',
        value: 'Fiji',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Finland',
        value: 'Finland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'France',
        value: 'France',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'French Guiana',
        value: 'French Guiana',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'French Polynesia',
        value: 'French Polynesia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'French Southern and Antarctic Lands',
        value: 'French Southern and Antarctic Lands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Gabon',
        value: 'Gabon',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Gambia',
        value: 'Gambia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Georgia',
        value: 'Georgia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Germany',
        value: 'Germany',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Ghana',
        value: 'Ghana',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Gibraltar',
        value: 'Gibraltar',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Greece',
        value: 'Greece',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Greenland',
        value: 'Greenland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Grenada',
        value: 'Grenada',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guadeloupe',
        value: 'Guadeloupe',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guam',
        value: 'Guam',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guatemala',
        value: 'Guatemala',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guernsey',
        value: 'Guernsey',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guinea',
        value: 'Guinea',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guinea-Bissau',
        value: 'Guinea-Bissau',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Guyana',
        value: 'Guyana',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Haiti',
        value: 'Haiti',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Heard Island and McDonald Islands',
        value: 'Heard Island and McDonald Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Honduras',
        value: 'Honduras',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Hong Kong',
        value: 'Hong Kong',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Hungary',
        value: 'Hungary',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Iceland',
        value: 'Iceland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'India',
        value: 'India',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Indonesia',
        value: 'Indonesia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Iran',
        value: 'Iran',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Iraq',
        value: 'Iraq',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Ireland',
        value: 'Ireland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Isle of Man',
        value: 'Isle of Man',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Israel',
        value: 'Israel',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Italy',
        value: 'Italy',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Jamaica',
        value: 'Jamaica',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Japan',
        value: 'Japan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Jersey',
        value: 'Jersey',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Jordan',
        value: 'Jordan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Kazakhstan',
        value: 'Kazakhstan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Kenya',
        value: 'Kenya',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Kiribati',
        value: 'Kiribati',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Kosovo',
        value: 'Kosovo',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Kuwait',
        value: 'Kuwait',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Kyrgyzstan',
        value: 'Kyrgyzstan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Laos',
        value: 'Laos',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Latvia',
        value: 'Latvia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Lebanon',
        value: 'Lebanon',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Lesotho',
        value: 'Lesotho',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Liberia',
        value: 'Liberia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Libya',
        value: 'Libya',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Liechtenstein',
        value: 'Liechtenstein',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Lithuania',
        value: 'Lithuania',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Luxembourg',
        value: 'Luxembourg',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Macau',
        value: 'Macau',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Macedonia (FYROM)',
        value: 'Macedonia (FYROM)',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Madagascar',
        value: 'Madagascar',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Malawi',
        value: 'Malawi',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Malaysia',
        value: 'Malaysia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Maldives',
        value: 'Maldives',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mali',
        value: 'Mali',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Malta',
        value: 'Malta',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Marshall Islands',
        value: 'Marshall Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Martinique',
        value: 'Martinique',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mauritania',
        value: 'Mauritania',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mauritius',
        value: 'Mauritius',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mayotte',
        value: 'Mayotte',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mexico',
        value: 'Mexico',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Micronesia',
        value: 'Micronesia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Moldova',
        value: 'Moldova',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Monaco',
        value: 'Monaco',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mongolia',
        value: 'Mongolia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Montenegro',
        value: 'Montenegro',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Montserrat',
        value: 'Montserrat',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Morocco',
        value: 'Morocco',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Mozambique',
        value: 'Mozambique',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Myanmar (Burma)',
        value: 'Myanmar (Burma)',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Namibia',
        value: 'Namibia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Nauru',
        value: 'Nauru',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Nepal',
        value: 'Nepal',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Netherlands',
        value: 'Netherlands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Netherlands Antilles',
        value: 'Netherlands Antilles',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'New Caledonia',
        value: 'New Caledonia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'New Zealand',
        value: 'New Zealand',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Nicaragua',
        value: 'Nicaragua',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Niger',
        value: 'Niger',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Nigeria',
        value: 'Nigeria',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Niue',
        value: 'Niue',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Norfolk Island',
        value: 'Norfolk Island',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'North Korea',
        value: 'North Korea',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Norway',
        value: 'Norway',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Oman',
        value: 'Oman',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Pakistan',
        value: 'Pakistan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Palau',
        value: 'Palau',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Palestine',
        value: 'Palestine',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Panama',
        value: 'Panama',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Papua New Guinea',
        value: 'Papua New Guinea',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Paraguay',
        value: 'Paraguay',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Peru',
        value: 'Peru',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Philippines',
        value: 'Philippines',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Pitcairn Islands',
        value: 'Pitcairn Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Poland',
        value: 'Poland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Portugal',
        value: 'Portugal',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Puerto Rico',
        value: 'Puerto Rico',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Qatar',
        value: 'Qatar',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Romania',
        value: 'Romania',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Russia',
        value: 'Russia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Rwanda',
        value: 'Rwanda',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Réunion',
        value: 'Réunion',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Barthélemy',
        value: 'Saint Barthélemy',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Helena',
        value: 'Saint Helena',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Kitts and Nevis',
        value: 'Saint Kitts and Nevis',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Lucia',
        value: 'Saint Lucia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Martin',
        value: 'Saint Martin',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Pierre and Miquelon',
        value: 'Saint Pierre and Miquelon',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saint Vincent and the Grenadines',
        value: 'Saint Vincent and the Grenadines',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Samoa',
        value: 'Samoa',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'San Marino',
        value: 'San Marino',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Sao Tome and Principe',
        value: 'Sao Tome and Principe',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Saudi Arabia',
        value: 'Saudi Arabia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Senegal',
        value: 'Senegal',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Serbia',
        value: 'Serbia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Seychelles',
        value: 'Seychelles',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Sierra Leone',
        value: 'Sierra Leone',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Singapore',
        value: 'Singapore',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Sint Maarten',
        value: 'Sint Maarten',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Slovakia',
        value: 'Slovakia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Slovenia',
        value: 'Slovenia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Solomon Islands',
        value: 'Solomon Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Somalia',
        value: 'Somalia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'South Africa',
        value: 'South Africa',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'South Georgia and the South Sandwich Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'South Korea',
        value: 'South Korea',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'South Sudan',
        value: 'South Sudan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Spain',
        value: 'Spain',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Sri Lanka',
        value: 'Sri Lanka',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Sudan',
        value: 'Sudan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Suriname',
        value: 'Suriname',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Svalbard and Jan Mayen',
        value: 'Svalbard and Jan Mayen',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Swaziland',
        value: 'Swaziland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Sweden',
        value: 'Sweden',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Switzerland',
        value: 'Switzerland',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Syria',
        value: 'Syria',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Taiwan',
        value: 'Taiwan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Tajikistan',
        value: 'Tajikistan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Tanzania',
        value: 'Tanzania',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Thailand',
        value: 'Thailand',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Togo',
        value: 'Togo',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Tokelau',
        value: 'Tokelau',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Tonga',
        value: 'Tonga',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Trinidad and Tobago',
        value: 'Trinidad and Tobago',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Tunisia',
        value: 'Tunisia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Turkey',
        value: 'Turkey',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Turkmenistan',
        value: 'Turkmenistan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Turks and Caicos Islands',
        value: 'Turks and Caicos Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Tuvalu',
        value: 'Tuvalu',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'U.S. Virgin Islands',
        value: 'U.S. Virgin Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Uganda',
        value: 'Uganda',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Ukraine',
        value: 'Ukraine',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'United Arab Emirates',
        value: 'United Arab Emirates',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'United Kingdom',
        value: 'United Kingdom',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'United States',
        value: 'United States',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'United States Minor Outlying Islands',
        value: 'United States Minor Outlying Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Uruguay',
        value: 'Uruguay',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Uzbekistan',
        value: 'Uzbekistan',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Vanuatu',
        value: 'Vanuatu',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Vatican City',
        value: 'Vatican City',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Venezuela',
        value: 'Venezuela',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Vietnam',
        value: 'Vietnam',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Wallis and Futuna',
        value: 'Wallis and Futuna',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Western Sahara',
        value: 'Western Sahara',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Yemen',
        value: 'Yemen',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Zambia',
        value: 'Zambia',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Zimbabwe',
        value: 'Zimbabwe',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
    {
        label: 'Åland Islands',
        value: 'Åland Islands',
        displayOrder: -1,
        doubleData: 0,
        hidden: false,
        description: '',
        readOnly: false,
    },
];
