import Http from '@tools/https';

// 初始化HubSpot embeded 表单
export function initHubSpot() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        script.charset = 'utf-8';
        // script.id = 'hs-script-loader';
        // script.async = true;
        // script.defer = true;

        document.body.appendChild(script);

        script.onload = (res) => {
            resolve(res);
        };
        script.onerror = (err) => {
            reject(err);
        };
    });
}

// 海外版 hubspot 申请试用表单
export const postHubspotForm = async (param) =>
    await Http.hspost(
        'https://api.hsforms.com/submissions/v3/integration/submit/139816465/edd31835-d476-48b0-9784-56f7fc39e09d',
        param,
    );

// 海外版 邮箱newsletter 收集表单
export const postSubscribeNews = async (param) =>
    await Http.hspost(
        // 'https://api.hsforms.com/submissions/v3/integration/submit/25488444/9b29896f-09a2-46cc-878e-1cffbf407f42',
        'https://api.hsforms.com/submissions/v3/integration/submit/139816465/bee81f06-9351-4847-94d6-5824b3c71140',
        param,
    );
